<!--
 * @Descripttion: 首页
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2020-12-26 10:38:11
-->
<template>
  <div class="pad vh">
    <van-swipe class="my-swipe home">
      <van-swipe-item v-for="(item,index) in sliderList" :key="index" class="flex-col-between pad-b " style="height:60vh;margin-top:10vh">
        <h3 class="f5">{{ item.title }}</h3>
        <div v-html="item.des" class="center fw"></div>
        <img :src="item.path" class="div7">
      </van-swipe-item>
    </van-swipe>
    <div class="flex" style="height:20vh">
      <button class="btn mar-t div5" @click="$router.push('/login')">前去使用</button>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from 'vant'
export default {
    data(){
        return{
          sliderList:[
            {path: '/static/img/slider/slider-1.png',title: '欢迎使用专家记', des: '<p style="color:#00CCB2">专家记 记专家 招标代理都用它<br> </p>'},
            {path: '/static/img/slider/slider-2.png',title: '招标代理机构的好帮手', des: '<p style="color:#00CCB2">为招标代理机构量身定制<br>能够随身携带的移动专家库</p>'},
            {path: '/static/img/slider/slider-3.png',title: '专家库功能', des: '<p style="color:#00CCB2">组建招标代理机构专属评标专家库<br>专家信息清晰展示</p>'},
            {path: '/static/img/slider/slider-4.png',title: '专家抽取功能', des: '<p style="color:#00CCB2">智能抽取算法抽取评标专家<br>抽取结果更符合实际业务需求</p>'},
          ]
        }
    },
    components:{
        [Swipe.name]:Swipe,
        [SwipeItem.name]:SwipeItem,
    }
}
</script>

<style scoped>
  /* .van-swipe-item{width: 100% !important;} */
  .van-swipe__indicator{background-color: red !important;}
</style>
